<template>
  <div class="managerUserOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.product.basicMsg')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table tableType="object" :custSpan="6" :data="custName.basic_information" :dataItem="basicInformation"></custome-table>
            </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('menu.memberAddress')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                <search-form ref="searchForm" :topVisible="true" :tabData="tabData" labelWidth="120px">
                  <template #topBtn>
                    <el-button type="primary" size="small" @click="handleExport">{{$t('button.batchExport')}}</el-button>
                    <el-button class="marginRight20" type="primary" size="small" @click="handleClick('create')">{{ $t('text.add') }}</el-button>
                  </template>
                </search-form>
                 <span class="view__text"></span>
                 <create-table :operationColWidth="150" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort">
                   <template #topButton>
                     <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
                     <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
                   </template>
                   <template #rightBtn="scope">
                     <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
                     <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
                   </template>
                 </create-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import { getMembersDetails, getMemberAddressSetup, getMemberAddressList, getMemberAddressExport, deleteMemberAddressSingle } from '@/services/members'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import jsonData from './jsonData'
// import { mixin } from '@/mixins/viewDiolog'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'memberDetail',
  components: {
    createTable,
    viewFrame,
    customeTable,
    allButton,
    searchForm
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      tableDataViewItem: jsonData.tableDataViewItem,
      historyTableItem: history.historyTableItem,
      basicInformation: jsonData.basicInformation,
      tableDataItem: jsonData.tableDataItem,
      dialog: {
        delOderItem: {
          visible: false,
          data: null,
          formDataRules: {
            quantity: [
              { required: true, message: this.$t('text.required'), trigger: 'change' }
            ]
          },
          formData: {
            quantity: 1
          }
        }
      },
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    next()
  },
  created () {
    this.init()
    this.search()
    this.queryData(getMemberAddressSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getMemberAddressSetup)
    this.isActive = true
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getMembersDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.user
        Object.assign(this.titleObject, { title: this.$t('form.members.memberName') + this.$t('text.symbol'), data: this.custName.user_name ? this.custName.user_name : '-' })
      }
      this.$store.state.app.loading.close()
      this.getDetail()
    },
    getDetail () {
      //  获取历史操作
      getHistoryList({ item_type: 'User', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    async dialogSearch () {
      this.init()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 导出数据接口
    handleExport () {
      this.$refs.searchForm.commonOperate(getMemberAddressExport, (data) => {
        this.$router.push({
          name: 'memberAddressDownloadCenter',
          params: { data }
        })
      }, this)
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type?.action === 'view') {
        this.$router.push({
          name: 'memberAddressDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'memberAddressEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteMemberAddressSingle)
      }
      if (type === 'create') {
        this.$router.push({
          name: 'memberAddressAdd',
          params: {
            id: this.$route.params.id
          }
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      this.searchData.user_id_eq = this.$route.params.id
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getMemberAddressList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scope>
.managerUserOrderView {
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
