// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'nickname'
  }, {
    prop: 'avatar',
    image: true
  }, {
    prop: 'client_member',
    back: true
  }, {
    prop: 'host'
  }, {
    prop: 'phone_number'
  }, {
    prop: 'birthday'
  }, {
    prop: 'current_sign_in_at'
  }, {
    prop: 'last_sign_in_at'
  }, {
    prop: 'last_sign_in_ip'
  }, {
    prop: 'sign_in_count'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }],
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'user',
    Object: 'value',
    label: '',
    width: '120'
  }, {
    prop: 'name',
    Object: 'value',
    label: '',
    width: '120'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'address',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'postal_code',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'unit_no',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'building_name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'current',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value',
    width: '160'
  }]
}
